import React from "react"
import SEO from "../components/seo"
import { Container, Button, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import "src/scss/sections/404.scss"

const NotFoundPage = () => (
  <div className="text-center">
    <SEO title="404: Not found" />
    <Container fluid className="error-page not-found">
      <Row>
        <Col>
          <h1 className="display-3">¡Lo sentimos!</h1>
          <h2 className="display-5">Error 404</h2>
          <p className="lead">No se encontró la página solicitada</p>
          <Link to="/">
            <Button>Volver al inicio</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)

export default NotFoundPage
